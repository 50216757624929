
// import libs
// import './libs/cookieBar';

var $header = $('#header');
var headerHeight = $header.outerHeight();

// import components
import './components/ie-detection.js';
import './components/menu.js';
import './components/initialScroll.js';
import intlTelInput from 'intl-tel-input';
// import './components/modal.js';

// init
// $('.lazy').Lazy({
//     // your configuration goes here
//     scrollDirection: 'vertical',
//     effect: 'fadeIn',
//     effectTime: 150,
//     visibleOnly: true,
//     threshold: 500,
//     bind: 'event',
//     onError: function(element) {
//         console.log('error loading ' + element.data('src'));
//     }
// });

// ----------------------------------------
//  scroll to
var $scrollToItems = $('.scrollTo');
$.each($scrollToItems, function() {
    $(this).on('click', function(e) {
        e.preventDefault();

        var href = $(this).attr('href');
        if (typeof href === 'undefined') {
            href = $(this).attr('data-href');
        }
        var offset = $(this).attr('data-offset');

        // if booking strip scroll to
        if ($(this).hasClass('scrolling-strip')) {
            const mq = window.matchMedia( "(max-width: 576px)" );
            if (mq.matches) {
                offset = 0;
            }
        }

        var $target = $(href);
        if ($target.length) {
            $('html,body').animate({
                scrollTop: $target.offset().top - headerHeight + ((typeof offset !== 'undefined') ? parseInt(offset) : 0)
            }, {
                duration: 300,
                easing: 'swing'
            });
        }
    });
});
// ----------------------------------------

// scripts here
// ----------------------------------------

// Bootstrap dropdown
// $('.dropdown-toggle').dropdown();

// collapse
// $('.collapse__toggle, .collapse__toggle > label').on('click', function(e) {
//     $(this).closest('.collapse').toggleClass('show');
// });


var $residenceAddressDiffers = $('#showOnResidence_address_differs');
$('#inputRessident_address_differs').on('change', function(e) {
    if($(this).is(':checked')) {
        $residenceAddressDiffers.show('fast');
    }
    else {
        $residenceAddressDiffers.hide('fast');
    }
});

// Magnific popup
// https://codepen.io/dimsemenov/pen/GAIkt
$('.gallery').each(function() {
    $(this).magnificPopup({
      delegate: 'a:not(.btn)',
      gallery: {
        enabled: true
      },
      type: 'image',
      removalDelay: 500, //delay removal by X to allow out-animation
      callbacks: {
        beforeOpen: function() {
          // just a hack that adds mfp-anim class to markup
           this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
           this.st.mainClass = this.st.el.attr('data-effect');
        }
      },
      closeOnContentClick: true,
      midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
    });
  });

  $('body').on('click', '.modal', function(e) {
    var $modal = $(this);
    if (!$(e.target).closest('.modal-content').length) {
        $modal.modal('hide');
    }
  });

//   $(document).on('click', function(e) {
//     if (!$(e.target).closest('.modal-content').length) {
//         $('.modal').modal('hide');
//     }
//   });
window.intlTelInput = intlTelInput;
