

// menu scripts
;(function ($) {

  "use strict";

  var elem = $('#focus-on-pageload');
  if(elem.length) {
    $([document.documentElement, document.body]).animate({
        scrollTop: elem.offset().top
    }, 300);
  }

})(jQuery);
